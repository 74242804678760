import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getlink-booking-aff',
  templateUrl: './getlink-booking-aff.component.html',
  styleUrls: ['./getlink-booking-aff.component.scss']
})
export class GetlinkBookingAffComponent implements OnInit {

  // variable
  linkAllTour = <any>"";
  linkTour = <any>"";

  token = <any>"";

  linkByTour = <any>"";
  tourLink = [
    {
      "id": 1,
      "tour": "TC-R01 : Doi Suthep Temple & Hmong Hill Tribe Village ",
      "link": "https://tour-in-chiangmai.com/tour/TC-R01"
    },
    {
      "id": 2,
      "tour": "TC-R02 : City Temple & Museum",
      "link": "https://tour-in-chiangmai.com/tour/TC-R02"
    },
    {
      "id": 3,
      "tour": "TC-R03 : Khan Toke Dinner & Market Exploring ",
      "link": "httpS://tour-in-chiangmai.com/tour/TC-R03"
    },
    {
      "id": 4,
      "tour": "TC-R04 : Chiang Rai Day Trip",
      "link": "https://tour-in-chiangmai.com/tour/TC-R04"
    },
    {
      "id": 5,
      "tour": "TC-R05 : Inthanon National Park",
      "link": "https://tour-in-chiangmai.com/tour/TC-R05"
    },
    {
      "id": 6,
      "tour": "TC-R06 : Trekking in Doi Suthep Area",
      "link": "https://tour-in-chiangmai.com/tour/TC-R06"
    },
    {
      "id": 7,
      "tour": "TC-R07 : Eco Walk in Kiew Mae Pan Nature Trail",
      "link": "https://tour-in-chiangmai.com/tour/TC-R07"
    },
    {
      "id": 8,
      "tour": "TC-E01 : Morning Buddhist Life",
      "link": "https://tour-in-chiangmai.com/tour/TC-E01"
    },
    {
      "id": 9,
      "tour": "TC-E02 : Hill Tribe Ways & Evening Chanting",
      "link": "https://tour-in-chiangmai.com/tour/TC-E02"
    },
    {
      "id": 10,
      "tour": "TC-E03 : The Rhythm of Chiang Mai",
      "link": "https://tour-in-chiangmai.com/tour/TC-E03"
    },
    {
      "id": 11,
      "tour": "TC-E04 : Appreciation of Celedon Making",
      "link": "https://tour-in-chiangmai.com/tour/TC-E04"
    },
    {
      "id": 12,
      "tour": "TC-E05 : Understand Local Life & Wisdom ",
      "link": "https://tour-in-chiangmai.com/tour/TC-E05"
    },
    {
      "id": 13,
      "tour": "TC-E06 : Variety of Lampang",
      "link": "https://tour-in-chiangmai.com/tour/TC-E06"
    },
  ];

  constructor() {
    
  }

  // 1. print
  public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(5));
    sessionStorage.setItem('sub-menu',JSON.stringify(501));
  }

  // 3. get token
  getToken(){
    // get storage
    let getUser = JSON.parse(localStorage.getItem('users'));
    if(getUser){
      this.token = getUser.data.token;
      this.setLinkTourProgram();
    }else{
      this.token = '';
    }
  }

  // 4. set link all tour
  setLinkTourProgram(){
    let website = "http://tour-in-chiangmai.com/tour";
    this.linkAllTour = website+';aff='+this.token;
    // console.log(this.linkAllTour);
  }

  // 5. set link tour program (by tour)
  setLinkTourProgramByTour(){
    let website = this.linkByTour;
    let length = this.tourLink.length;
    let tourId = <any>"";

    if(this.linkByTour.link==""){
      this.linkTour = "";
    }else{
      for(let i=0; i<length; i++){
        if(this.tourLink[i].link==website){
          tourId = this.tourLink[i].id * 23 + 1327;
        }
      }
      this.linkTour = website+';aff='+this.token;
    }
  }

  // 6. copy link all program
  copyLink(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  // 7. copy link by program
  copyLinkByProgram(){

  }

  ngOnInit() {
    this.activeMenu();
    this.getToken();
  }

}
