import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';

@Component({
  selector: 'app-inthanon-national-park',
  templateUrl: './inthanon-national-park.component.html',
  styleUrls: ['./inthanon-national-park.component.scss']
})
export class InthanonNationalParkComponent implements OnInit {
  public Databooking = <any>'';
  public getDetails = <any>'';
  public privacy = <any>'';
  public privacy_ : boolean = false;
  public detail_spc : boolean = false;
  public guide = <any>'';
  public guide_tel = <any>'';
  public driver = <any>'';
  public driver_tel = <any>'';
  public count = <any>'';
  public user = <any>'';
  public username = <any>'';
  public date = new Date();
  public noted = <any>'';
  public noted_check : boolean = false;
  

 // -------------------------- value join ----------------------------
  // variable of Details Join
  public time_pickup = "08.00";
  public cost = {
      foreigner : 300,
      child : 150,
      thai : 50,
      van_fee : 30,
      market : 40,
      vagetatian : <any>'',
      lunch : <any>''
  };
  public unit = {
      foreigner : <any>'',
      child : <any>'',
      thai : <any>'',
      van_fee : 1,
      market : <any>'',
      vagetatian : <any>'',
      lunch : <any>'',
      pax : <any>'',
      pax_of_vagetatian : <any>'',
      pax_of_lunch : <any>''
  };
  public amount = {
      foreigner : <any>'',
      child : <any>'',
      thai : <any>'',
      van_fee : <any>'',
      market : <any>'',
      vagetatian : <any>'',
      lunch : <any>'',
      sum : <any>''
  }

  // -------------------------- value private ----------------------------
  // variable of Details Private
  public cost_private = {
    foreigner : 300,
    child : 150,
    thai : 50,
    van_fee : 30,
    market : 40,
    vagetatian : <any>'',
    lunch : <any>'',
    more_1 : <any>'',
    more_2 : <any>''
};
public unit_private = {
    foreigner : <any>'',
    child : <any>'',
    thai : <any>'',
    van_fee : 1,
    market : <any>'',
    vagetatian : <any>'',
    lunch : <any>'',
    pax : <any>'',
    pax_of_vagetatian : <any>'',
    pax_of_lunch : <any>'',
    more_1 : <any>'',
    more_2 : <any>''
};
public amount_private = {
    foreigner : <any>'',
    child : <any>'',
    thai : <any>'',
    van_fee : <any>'',
    market : <any>'',
    vagetatian : <any>'',
    lunch : <any>'',
    sum : <any>'',
    more_1 : <any>'',
    more_2 : <any>''
};
public detail_private_spc : any[] = [{
    detail_spc : '',
    unit_spc : '',
    cost_spc : '',
    amount_spc : ''
}];


  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  // get data Program Tour
public getDetailTours(){
  // post to API
  let url = "./../../../../assets/json/joborder/details_of_tours.json";
  let options = new RequestOptions();
  return this.http.get(url,options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      this.getDetails= data,
                      
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
}

  // get select book to create job order
  public getBookingSelect(){
    this.Databooking = sessionStorage.getItem('invoice_select');
    this.Databooking = JSON.parse(this.Databooking);

        //check privacy
        if(this.Databooking[0].tourPrivacy == "Join"){
            this.privacy_ = false;
        }else{
            this.privacy_ = true;
        }
  }

  // set unit at details & process Amount
  public getunitBooking(){

    this.cost.vagetatian = 0;

    this.unit.thai = 0;

    //get adult_pax
    let adult_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        adult_pax = adult_pax + this.Databooking[i].adultpax;
      }
    //get chlid_pax
    let child_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        child_pax = child_pax + this.Databooking[i].childpax;
      }
      //get infant_pax
    let infant_pax = 0;
    for(let i=0;i<=this.Databooking.length-1;i++){
      infant_pax = infant_pax + this.Databooking[i].infantpax;
    }
    // all pax
    this.unit.pax = adult_pax + child_pax;

    //set amount unit for Food Price
    this.unit.pax_of_lunch = this.unit.pax;
    this.unit.lunch = this.unit.pax;

    this.processPriceFood();

    //process Amount
      this.amount.foreigner = adult_pax * this.cost.foreigner;
      this.amount.child = child_pax * this.cost.child;
      this.amount.thai = this.unit.thai * this.cost.thai;
      this.amount.market = this.unit.pax * this.cost.market;
      this.unit.foreigner = adult_pax;
      this.unit.child = child_pax;
      this.amount.van_fee = this.cost.van_fee*this.unit.van_fee;
      this.amount.vagetatian = this.cost.vagetatian * 1;
      this.amount.lunch = this.cost.lunch * 1;
      this.amount.sum = this.amount.foreigner + this.amount.child + this.amount.market + this.amount.van_fee + this.amount.vagetatian + this.amount.lunch;
  }

  public changePrice(){
    this.amount.lunch = this.cost.lunch * 1;
    this.amount.vagetatian = this.cost.vagetatian * 1;
    this.amount.thai = this.unit.thai * this.cost.thai;
    this.amount.sum = this.amount.foreigner + this.amount.child + this.amount.market + this.amount.van_fee + this.amount.vagetatian + this.amount.lunch + this.amount.thai ;
  }

  public processPriceFood(){
        if(this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 2){         //lunch = 2 & vagetatian = 2,3,4,5,6,7 pax
            this.cost.lunch = 440;                                                  // lunch 400 bath
            this.cost.vagetatian = 440;                                             
        }if(this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 3){
            this.cost.lunch = 440;
            this.cost.vagetatian = 560;
        }if(this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 4 ){
          this.cost.lunch = 440;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 5 || this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 6 || this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 7){
          this.cost.lunch = 440;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 3 && this.unit.pax_of_vagetatian == 2 ){        //lunch = 3 & vagetatian = 2,3,4,5,6 pax
          this.cost.lunch = 600;                                                      // luch 600 bath
          this.cost.vagetatian = 680;                                                 
        }if(this.unit.pax_of_lunch == 3 && this.unit.pax_of_vagetatian == 3){
            this.cost.lunch = 600;
            this.cost.vagetatian = 560;
        }if(this.unit.pax_of_lunch == 3 && this.unit.pax_of_vagetatian == 4 ){
          this.cost.lunch = 600;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 3 && this.unit.pax_of_vagetatian == 5 || this.unit.pax_of_lunch == 2 && this.unit.pax_of_vagetatian == 6){
          this.cost.lunch = 600;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 4 && this.unit.pax_of_vagetatian == 2){         //lunch = 4 & vagetatian = 2,3,4,5 pax
            this.cost.lunch = 880;                                                   // lunch 880 bath
            this.cost.vagetatian = 440;                                              
        }if(this.unit.pax_of_lunch == 4 && this.unit.pax_of_vagetatian == 3){
            this.cost.lunch = 880;
            this.cost.vagetatian = 560;
        }if(this.unit.pax_of_lunch == 4 && this.unit.pax_of_vagetatian == 4 ){
          this.cost.lunch = 880;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 4 && this.unit.pax_of_vagetatian == 5){
          this.cost.lunch = 880;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 5 && this.unit.pax_of_vagetatian== 2){         //lunch = 5 & vagetatian = 2,3,4, pax
            this.cost.lunch = 1040;                                                  // lunch 1040 bath
            this.cost.vagetatian = 440;                                              
        }if(this.unit.pax_of_lunch == 5 && this.unit.pax_of_vagetatian == 3){
            this.cost.lunch = 1040;
            this.cost.vagetatian = 560;
        }if(this.unit.pax_of_lunch == 5 && this.unit.pax_of_vagetatian == 4 ){
          this.cost.lunch = 1040;
          this.cost.vagetatian = 680;
        }if(this.unit.pax_of_lunch == 6 && this.unit.pax_of_vagetatian == 2){         //lunch = 6 & vagetatian = 2,3 pax
            this.cost.lunch = 1360;                                                  // lunch 1360 bath
            this.cost.vagetatian = 440;                                              
        }if(this.unit.pax_of_lunch == 6 && this.unit.pax_of_vagetatian == 3){
            this.cost.lunch = 1360;
            this.cost.vagetatian = 560;
        }if(this.unit.pax_of_lunch == 7 && this.unit.pax_of_vagetatian == 2){         //lunch = 7 & vagetatian = 2 pax
            this.cost.lunch = 1610;                                                  // lunch 1610 bath
            this.cost.vagetatian = 440;                                              

    // Singel Style Food    

        }if(this.unit.pax_of_lunch  == 2 && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 2 pax | lunch 440 bath
          this.cost.lunch  = 440;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 3  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 3 pax | lunch 600 bath 
          this.cost.lunch  = 600;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 4  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 4 pax | lunch 880 bath
          this.cost.lunch = 880;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 5  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 5 pax | lunch 1040 bath
          this.cost.lunch = 1040;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 6  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 6 pax | lunch 1360 bath
          this.cost.lunch  = 1360;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 7  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 7 pax | lunch 1610 bath
          this.cost.lunch  = 1610;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 8  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 8 pax | lunch 1760 bath
          this.cost.lunch  = 1760;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 9  && this.unit.pax_of_vagetatian == 0){                                                  //lunch = 9 pax | lunch 1760 bath
          this.cost.lunch  = 1760;
          this.unit.vagetatian = 0;
        }if(this.unit.pax_of_lunch  == 2  && this.unit.pax_of_vagetatian == 0){                                                  //vagetatian = 2 pax | vagetatian 440 bath
          this.cost.vagetatian = 440;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 3 && this.unit.pax_of_lunch == 0){                                                   //vagetatian = 3 pax | vagetatian 600 bath 
          this.cost.vagetatian = 600;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 4  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 4 pax | vagetatian 880 bath
          this.cost.vagetatian = 880;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 5  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 5 pax | vagetatian 1040 bath
          this.cost.vagetatian = 1040;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 6  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 6 pax | vagetatian 1360 bath
          this.cost.vagetatian = 1360;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 7  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 7 pax | vagetatian 1610 bath
          this.cost.vagetatian = 1610;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 8  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 8 pax | vagetatian 1760 bath
          this.cost.vagetatian = 1760;
          this.unit.lunch = 0;
        }if(this.unit.pax_of_vagetatian == 9  && this.unit.pax_of_lunch == 0){                                                  //vagetatian = 9 pax | vagetatian 1760 bath
          this.cost.vagetatian = 1760;
          this.unit.lunch = 0;
        }
  }

  // ----------------------------------------------------------------------------|  Detail Private Process  |------------------------------------------------------------------------------------- 

  //5.set unit at details & process Amount
  public getunitBooking_(){
    //change unit vagetatian & lunch
    this.unit_private.vagetatian = 0;
    this.unit_private.pax_of_vagetatian = 0; 
    this.cost_private.vagetatian = 0;

    this.unit_private.thai = 0;

    //get adult_pax
    let adult_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        adult_pax = adult_pax + this.Databooking[i].adultpax;
      }
    //get chlid_pax
    let child_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        child_pax = child_pax + this.Databooking[i].childpax;
      }
      //get infant_pax
    let infant_pax = 0;
    for(let i=0;i<=this.Databooking.length-1;i++){
      infant_pax = infant_pax + this.Databooking[i].infantpax;
    }
    // all pax
    this.unit_private.pax = adult_pax + child_pax;

    this.unit_private.lunch = this.unit_private.pax ;
    this.unit_private.pax_of_lunch  = 2;

    this.processPriceFood_();

    //process Amount
      this.amount_private.foreigner = adult_pax * this.cost.foreigner;
      this.amount_private.child = child_pax * this.cost.child;
      this.amount_private.thai = this.unit.thai * this.cost.thai;
      this.amount_private.market = this.unit.pax * this.cost.market;
      this.unit_private.foreigner = adult_pax;
      this.unit_private.child = child_pax;
      this.amount_private.van_fee = this.cost.van_fee*this.unit.van_fee;
      this.amount_private.vagetatian = this.cost_private.vagetatian * 1;
      this.amount_private.lunch = this.cost_private.lunch * 1;
      this.amount_private.sum = this.amount_private.foreigner + this.amount_private.child + this.amount_private.market + this.amount_private.van_fee + this.amount_private.vagetatian + this.amount_private.lunch;
  }

  public changePrice_(){
    this.amount_private.lunch = this.cost_private.lunch * 1;
    this.amount_private.vagetatian = this.cost_private.vagetatian * 1;
    this.amount_private.thai = this.unit_private.thai * this.cost_private.thai;
    this.amount_private.market = this.unit_private.pax * this.cost.market;
    this.amount_private.more_1 = this.unit_private.more_1 * this.cost_private.more_1;
    this.amount_private.more_2 = this.unit_private.more_2 * this.cost_private.more_2;
    this.amount_private.sum = this.amount_private.foreigner + this.amount_private.child + this.amount_private.market + this.amount_private.van_fee + this.amount_private.vagetatian + this.amount_private.lunch + this.amount_private.thai + this.amount_private.more_1 + this.amount_private.more_2;
    
    if(this.amount_private.more_1 == 0 && this.unit_private.more_1 == 0 && this.cost_private.more_1 == 0 ){
          this.amount_private.more_1 = null;
    }else if(this.amount_private.more_1 == 0 && this.unit_private.more_1 != 0 || this.amount_private.more_1 == 0 && this.cost_private.more_1 != 0 ){
          this.amount_private.more_1 = 0;
    } 
    if(this.amount_private.more_2 == 0 && this.unit_private.more_2 == 0 && this.cost_private.more_2 == 0 ){
      this.amount_private.more_2 = null;
    }else if(this.amount_private.more_2 == 0 && this.unit_private.more_2 != 0 || this.amount_private.more_2 == 0 && this.cost_private.more_2 != 0 ){
      this.amount_private.more_2 = 0;
    }


    for(let i=0;i<=this.detail_private_spc.length;i++){
      this.detail_private_spc[i].amount_spc = this.detail_private_spc[i].unit_spc * this.detail_private_spc[i].cost_spc;
      this.amount_private.sum = this.amount_private.sum + this.detail_private_spc[i].amount_spc;
      if(this.detail_private_spc[i].amount_spc == 0 && this.detail_private_spc[i].unit_spc == 0 && this.detail_private_spc[i].cost_spc == 0 ){
        this.detail_private_spc[i].amount_spc = null;
      }else if(this.detail_private_spc[i].amount_spc == 0 && this.detail_private_spc[i].unit_spc != 0 || this.detail_private_spc[i].amount_spc == 0 && this.detail_private_spc[i].cost_spc != 0){
        this.detail_private_spc[i].amount_spc = 0;
      }
    }
  }

  public processPriceFood_(){
        if(this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 2){         //lunch = 2 & vagetatian = 2,3,4,5,6,7 pax
            this.cost_private.lunch = 440;                                                  // lunch 400 bath
            this.cost_private.vagetatian = 440;                                             
        }if(this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 3){
            this.cost_private.lunch = 440;
            this.cost_private.vagetatian = 560;
        }if(this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 4 ){
          this.cost_private.lunch = 440;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 5 || this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 6 || this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 7){
          this.cost_private.lunch = 440;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 3 && this.unit_private.pax_of_vagetatian == 2 ){        //lunch = 3 & vagetatian = 2,3,4,5,6 pax
          this.cost_private.lunch = 600;                                                      // luch 600 bath
          this.cost_private.vagetatian = 680;                                                 
        }if(this.unit_private.pax_of_lunch == 3 && this.unit_private.pax_of_vagetatian == 3){
            this.cost_private.lunch = 600;
            this.cost_private.vagetatian = 560;
        }if(this.unit_private.pax_of_lunch == 3 && this.unit_private.pax_of_vagetatian == 4 ){
          this.cost_private.lunch = 600;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 3 && this.unit_private.pax_of_vagetatian == 5 || this.unit_private.pax_of_lunch == 2 && this.unit_private.pax_of_vagetatian == 6){
          this.cost_private.lunch = 600;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 4 && this.unit_private.pax_of_vagetatian == 2){         //lunch = 4 & vagetatian = 2,3,4,5 pax
            this.cost_private.lunch = 880;                                                   // lunch 880 bath
            this.cost_private.vagetatian = 440;                                              
        }if(this.unit_private.pax_of_lunch == 4 && this.unit_private.pax_of_vagetatian == 3){
            this.cost_private.lunch = 880;
            this.cost_private.vagetatian = 560;
        }if(this.unit_private.pax_of_lunch == 4 && this.unit_private.pax_of_vagetatian == 4 ){
          this.cost_private.lunch = 880;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 4 && this.unit_private.pax_of_vagetatian == 5){
          this.cost_private.lunch = 880;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 5 && this.unit_private.pax_of_vagetatian == 2){         //lunch = 5 & vagetatian = 2,3,4, pax
            this.cost_private.lunch = 1040;                                                  // lunch 1040 bath
            this.cost_private.vagetatian = 440;                                              
        }if(this.unit_private.pax_of_lunch == 5 && this.unit_private.pax_of_vagetatian == 3){
            this.cost_private.lunch = 1040;
            this.cost_private.vagetatian = 560;
        }if(this.unit_private.pax_of_lunch == 5 && this.unit_private.pax_of_vagetatian == 4 ){
          this.cost_private.lunch = 1040;
          this.cost_private.vagetatian = 680;
        }if(this.unit_private.pax_of_lunch == 6 && this.unit_private.pax_of_vagetatian == 2){         //lunch = 6 & vagetatian = 2,3 pax
            this.cost_private.lunch = 1360;                                                  // lunch 1360 bath
            this.cost_private.vagetatian = 440;                                              
        }if(this.unit_private.pax_of_lunch == 6 && this.unit_private.pax_of_vagetatian == 3){
            this.cost_private.lunch = 1360;
            this.cost_private.vagetatian = 560;
        }if(this.unit_private.pax_of_lunch == 7 && this.unit_private.pax_of_vagetatian == 2){         //lunch = 7 & vagetatian = 2 pax
            this.cost_private.lunch = 1610;                                                  // lunch 1610 bath
            this.cost_private.vagetatian = 440;                                              

    // Singel Style Food    

        }if(this.unit_private.pax_of_lunch  == 2 && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 2 pax | lunch 440 bath
          this.cost_private.lunch  = 440;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 3  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 3 pax | lunch 600 bath 
          this.cost_private.lunch  = 600;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 4  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 4 pax | lunch 880 bath
          this.cost_private.lunch = 880;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 5  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 5 pax | lunch 1040 bath
          this.cost_private.lunch = 1040;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 6  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 6 pax | lunch 1360 bath
          this.cost_private.lunch  = 1360;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 7  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 7 pax | lunch 1610 bath
          this.cost_private.lunch  = 1610;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 8  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 8 pax | lunch 1760 bath
          this.cost_private.lunch  = 1760;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_lunch  == 9  && this.unit_private.pax_of_vagetatian == 0){                                                  //lunch = 9 pax | lunch 1760 bath
          this.cost_private.lunch  = 1760;
          this.cost_private.vagetatian = 0;
        }if(this.unit_private.pax_of_vagetatian  == 2  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 2 pax | vagetatian 440 bath
          this.cost_private.vagetatian = 440;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 3 && this.unit_private.pax_of_lunch == 0){                                                   //vagetatian = 3 pax | vagetatian 600 bath 
          this.cost_private.vagetatian = 600;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 4  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 4 pax | vagetatian 880 bath
          this.cost_private.vagetatian = 880;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 5  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 5 pax | vagetatian 1040 bath
          this.cost_private.vagetatian = 1040;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 6  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 6 pax | vagetatian 1360 bath
          this.cost_private.vagetatian = 1360;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 7  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 7 pax | vagetatian 1610 bath
          this.cost_private.vagetatian = 1610;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 8  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 8 pax | vagetatian 1760 bath
          this.cost_private.vagetatian = 1760;
          this.cost_private.lunch = 0;
        }if(this.unit_private.pax_of_vagetatian == 9  && this.unit_private.pax_of_lunch == 0){                                                  //vagetatian = 9 pax | vagetatian 1760 bath
          this.cost_private.vagetatian = 1760;
          this.cost_private.lunch = 0;
        }
  }

  // get user data
    public getUserData(){
      this.user = localStorage.getItem('users');
      this.user = JSON.parse(this.user);
      this.username = this.user.data.name;
  }

  //plus label detail job
  public add_private_spc() {
    if(this.detail_spc == false){
        this.detail_spc = true;
    }else{
    this.detail_private_spc.push({
      detail_spc: '',
      unit_spc: '',
      cost_spc: '',
      amount_spc: ''
    });
    }
  }

  //get DATA Guide & Driver
    public getDataGuidesDirver(){
      let DataGuidesDirver;
      DataGuidesDirver = sessionStorage.getItem('guide_driver');
      DataGuidesDirver = JSON.parse(DataGuidesDirver);
      this.guide = DataGuidesDirver.data.Guide;
      this.driver = DataGuidesDirver.data.Driver;
      this.guide_tel = DataGuidesDirver.data.Phone_Guide;
      this.driver_tel = DataGuidesDirver.data.Phone_Driver;
    }


  removedetail(i: number) {
    this.detail_private_spc.splice(i, 1);
    this.changePrice_();
  }

  // Print Job Order
  joborder_print(){
    //check Noted not null
    if(this.noted == null || this.noted == undefined || this.noted == ''  ){
        this.noted_check = true;
        setTimeout(function(){ 
        window.print();
      }, 1000);

    }else{
        this.noted_check = false;
        setTimeout(function(){ 
          window.print();
        }, 1000);
    }
  }
    
  ngOnInit() {
      this.getDetailTours();
      this.getBookingSelect();
      this.getunitBooking();
      this.getunitBooking_();
      this.getUserData();
      this.getDataGuidesDirver();
  }

}
