import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  constructor() { }

    // 1. print
    public print():void {
      window.print();
    }
  
    // 2. active menu
    public activeMenu(){
      // set storage
      sessionStorage.setItem('menu',JSON.stringify(5));
      sessionStorage.setItem('sub-menu',JSON.stringify(501));
    }

  ngOnInit() {
  }

}
