import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn } from '@angular/forms';
import { Session } from 'protractor';

@Component({
  selector: 'app-add-job-order',
  templateUrl: './add-job-order.component.html',
  styleUrls: ['./add-job-order.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class AddJobOrderComponent implements OnInit {
  public getDataTour ={
    transactionId: <any>'',
    bookingId: <any>'',
    invoiceId: <any>'',
    tourName: <any>'',
    tourFullname: <any>'',
    tourPrivacy: <any>'',
    tourTravel: <any>'',
    tourPax: <any>'',
    hotel: <any>'',
    hotelRoom: <any>'',
    bookBy: <any>'',
    noteBy: <any>'',
    insurance: <any>'',
    price: <any>'',
    guestName: <any>''
  };
  public getBooking = <any>'';
  public TourInfo = {
    tourData : <any>'',
    travelDate : <any>'',
    privacy : <any>''
  };
  public _travelDate = <any>'';
  public _event_Drop = <any>'';
  public FormArraySelect: Array<any> = [];
  public DataGuidesDrivers = <any>'';
  public GuidesArr = <any>'';
  public DriversArr = <any>'';
  public GuidesArr_ : Array<any> = [];
  public GuidesArr_tel = <any>'';
  public DriversArr_ : Array<any> = [];
  public DriversArr_tel = <any>'';


  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    }

   // 1. print
   public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(3));
    sessionStorage.setItem('sub-menu',JSON.stringify(301));
  }

// 3. get data Program Tour
public getReservationDetail(){
  // post to API
  let url = "./../../../../assets/json/reservations/bookingData.json";
  let options = new RequestOptions();
  return this.http.get(url,options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      this.getDataTour = data,
                      // this.bindingData(data),
                       console.log(this.getDataTour)
                      
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
}

// 4. get Data Booking Custom
public searchGuests(){
  this.TourInfo.travelDate = this._travelDate.toISOString().slice(0,10); 
  // sessionStorage.setItem('travelDate',JSON.stringify(this.TourInfo.travelDate));
  // this.TourInfo.travelDate = sessionStorage.getItem('travelDate');
  // post to API
  let setData = {
    TourInfo: this.TourInfo
  }
  // let url ="http://api.tourinchiangmai.com/api/Reservations/getDataBookingforJob";
  let url ="http://localhost:9000/api/Reservations/getDataBookingforJob";
  let options = new RequestOptions();
  return this.http.post(url,setData,options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      this.getBooking = data.data,
                      this.bindingData(data.data)
                      
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
    }

    bindingData(data){
      this.getBooking.transactionId = data.transactionId 
      this.getBooking.bookingId = data.bookingId
      this.getBooking.invoiceId = data.invoiceId
      this.getBooking.tourName = data.tourName
      this.getBooking.tourFullname = data.tourFullname
      this.getBooking.tourPrivacy = data.tourPrivacy
      this.getBooking.tourTravel = data.tourTravel
      this.getBooking.tourPax = data.tourPax
      this.getBooking.hotel = data.hotel
      this.getBooking.hotelRoom = data.hotelRoom 
      this.getBooking.bookBy = data.bookBy
      this.getBooking.noteBy = data.noteBy
      this.getBooking.insurance = data.insurance 
      this.getBooking.price = data.price
      this.getBooking.guestName = data.guestName
      this.setDataTouronSession();
    }

    //set Data Book to SessionStorage
    setDataTouronSession(){
      sessionStorage.setItem('book_Data' , JSON.stringify(this.getBooking));
    }

    //ChackBox book
    onChange(invoic_select:string, isChecked: boolean){
      if(isChecked){
        this.FormArraySelect.push(invoic_select);
      }else{
        let index =
        this.FormArraySelect.indexOf(invoic_select);
        this.FormArraySelect.splice(index,1);
      }
      console.log(this.FormArraySelect);
    }

    //Select Book to create Job Order & Voucher
    createjob(){
        let select_booking: Array<any> = [];
        if(this.FormArraySelect){
          for(let i=0;i<=this.FormArraySelect.length-1;i++){
                for(let j=0;j<=this.getBooking.length-1;j++){
                    if(this.FormArraySelect[i] == this.getBooking[j].invoiceId){
                        select_booking[i] = this.getBooking[j];
                    }
                }
          }

          // Guide tel
          for(let i=0; i <= this.GuidesArr_.length; i++){
            if(this.GuidesArr == this.GuidesArr_[i].nickname){
              this.GuidesArr_tel = this.GuidesArr_[i].tel;
              break;
            }
          }
          // Driver tel
          for(let i=0; i <= this.DriversArr_.length; i++){
            if(this.DriversArr == this.DriversArr_[i].nickname){
              this.DriversArr_tel = this.DriversArr_[i].tel;
              break;
            }
          }

            let setDataGuide_Driver = {
               data : {
                Guide : this.GuidesArr ,
                Phone_Guide : this.GuidesArr_tel,
                Driver : this.DriversArr,
                Phone_Driver : this.DriversArr_tel
               }
            };
            sessionStorage.setItem('invoice_select' , JSON.stringify(select_booking));
            sessionStorage.setItem('guide_driver' , JSON.stringify(setDataGuide_Driver));
      }
    }

    getDataGuidesDrivers(){
    //get Data Guides and Drivers
    // let url ="http://api.tourinchiangmai.com/api/Reservations/getDataGudeandDriver";
    let url ="http://localhost:9000/api/Reservations/getDataGudeandDriver";

    let options = new RequestOptions();
    return this.http.post(url,options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      this.DataGuidesDrivers = data,
                      console.log(this.DataGuidesDrivers.guide),
                      this.newArrayGuidesDrivers()
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
      }

      public newArrayGuidesDrivers(){
          for(let i=0;i<=this.DataGuidesDrivers.guide.length-1;i++){
                this.GuidesArr_.push(this.DataGuidesDrivers.guide[i]);
          }
          for(let i=0;i<=this.DataGuidesDrivers.driver.length-1;i++){
            this.DriversArr_.push(this.DataGuidesDrivers.driver[i]);
        }
      }

      // select guide & driver to create Job Order
      public selectGuide(){
    
      }

      // if(this.FormArraySelect){
      //   for(let i=0;i<=this.FormArraySelect.length-1;i++){
      //         for(let j=0;j<=this.getBooking.length-1;j++){
      //             if(this.FormArraySelect[i] == this.getBooking[j].invoiceId){
      //                 select_booking[i] = this.getBooking[j];
      //             }
      //         }
      //   }
      // }

      public setTypeJob_guide(){
        //set Session Tpye of Job Order
        sessionStorage.setItem('typeJobOrder' , 'guide');
      }
      public setTypeJob_driver(){
        //set Session Tpye of Job Order
        sessionStorage.setItem('typeJobOrder' , 'driver');
      }

  ngOnInit() {
    this.getReservationDetail();
    this.getDataGuidesDrivers();
  }

}
