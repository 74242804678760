import { Component, OnInit, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, } from "@angular/forms";
import "rxjs/Rx";
import * as AOS from 'aos';
declare var $: any;

@Injectable({  
  providedIn: 'root'  
})  

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {

  data = {
    username: '',
    password: ''
  };

  public registrationForm: FormGroup;
  public ieldTextType: boolean;
  public showEyePass : boolean;
  public hideEyePass : boolean;
  public typeInput = <any>'';


  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { 
    this.typeInput = 'password';
    this.showEyePass  = true;
  }

  /*
    1. Login
    2. Set login
  */

  // 1. Login
  login(){
    // let url = 'http://localhost:9000/api/Account/AccountLogin';
    let url = 'http://api.tourinchiangmai.com/api/Account/AccountLogin';

      let options = new RequestOptions();
      let dataSave = {
          "username": this.data.username,
          "password": this.data.password
      };

      return this.http.post(url, dataSave, options)
                      .map(res => res.json())
                      .subscribe(
                        data => [
                          // console.log(data),
                          this.setLogin(data)
                        ],
                        err => {console.log(err)}
                      );
  }

  // 2. Set login
  setLogin(data){
    // console.log(data);
    if(data.status==true){
      // alert(data.message);
      localStorage.setItem('users',JSON.stringify(data));
      this.router.navigate(['user']);
    }else if(data.status==false && data.notify=='Sign out not found'){
      alert(data.message);
      this.router.navigate(['user/force-logout/'+data.data.token]);
    }else if(data.status==false && data.notify=='Non active'){
      alert(data.message);
      this.router.navigate(['user/register-confirm/'+data.data.token]);
    }else{
      alert(data.message);
    }
  }

  // key press enter
  keyPressEnter(){
    // username
    var input1 = document.getElementById("form-input1");
    input1.addEventListener("keyup", function(event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        document.getElementById("login-btn").click();
      }
    });

    // password
    var input2 = document.getElementById("form-input2");
    input2.addEventListener("keyup", function(event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        document.getElementById("login-btn").click();
      }
    });
  }

  //check reload
  checkReload(){
    let check = sessionStorage.getItem('reload');
    if(check){
      sessionStorage.removeItem('reload');
      window.location.reload();
    }
  }

  changeEye(value: number){
      if(value == 1){
            this.typeInput = 'password';
            this.showEyePass = true;
            console.log(value);
      }
      if(value == 2){
            this.typeInput = 'text';
            this.showEyePass = false;
            console.log(value);
      }
  }


  ngOnInit(){
    // this.keyPressEnter();
    this.checkReload();
    AOS.init({
      easing: 'ease',
      once: true
    });
  }

}
