import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-template-tc-website',
  templateUrl: './template-tc-website.component.html',
  styleUrls: ['./template-tc-website.component.scss']
})
export class TemplateTcWebsiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href="/#/user";
  }

}
