import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss']
})
export class AdminProfileComponent implements OnInit {

  // variable
  public profile = {
    fullname: <any>'',
    birth: <any>'',
    tel: <any>'',
    email: <any>'',
    nationality: <any>'',
    address: <any>'',
    idNumber: <any>'',
    profilePicture: <any>'',
    copyIdCard: <any>''
  };

  userToken = <any>'';
  AdminData = <any>'';
  checkupdate = <any>'';


  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  buttonDisabled : boolean = true;

   // 1. print
   public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(3));
    sessionStorage.setItem('sub-menu',JSON.stringify(301));
  }

  // 3. get data from api
  public getAdminDetail(){
    // get token
     let getUser = JSON.parse(localStorage.getItem('users'));
     let userToken = getUser.data.token;

    // set data
    let setData = {
     userToken: userToken,
    };
    console.log(setData);

    // post to API
     //  let url = 'http://localhost:9000/api/Dashboard/Admin/Profile';
     let url = 'http://api.tourinchiangmai.com/api/Dashboard/Admin/Profile';

    let options = new RequestOptions();

    return this.http.post(url, setData, options)
                    .map(res => res.json())
                    .subscribe(
                      data => [

                        this.AdminData = data.data,
                        this.bindingData(data.data),
                          console.log(this.AdminData)
                        
                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

//set Data to Form
  bindingData(data){
    this.profile.fullname = data.profile.fullname;
    this.profile.birth = data.profile.birth;
    console.log(this.profile.birth);
    this.profile.tel = data.profile.tel;
    this.profile.email = data.profile.email; 
    this.profile.nationality = data.profile.nationality;
    this.profile.address = data.profile.address;
    this.profile.idNumber = data.profile.idNumber;
    //  console.log(this.profile.email);
  }
  
// ------------------------------------------------------------------------------------------------------------------------------
  

// 4. sent update data from
public UpdateAdminProfile(){
  // get token
   let getUser = JSON.parse(localStorage.getItem('users'));
   let userToken = getUser.data.token;

  // set data
  let setData = {
   token: userToken,
   updateprofile : this.profile,

  };
  console.log(setData);

  // post to API
   //  let url = 'http://localhost:9000/api/Dashboard/Admin/UpdateProfile';
   let url = 'http://api.tourinchiangmai.com/api/Dashboard/Admin/UpdateProfile';

  let options = new RequestOptions();

  return this.http.post(url, setData, options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      // this.checkupdate = data.status,
                      sessionStorage.setItem('check-update',JSON.stringify(data)),
                      this.bindingGetData()
                      // console.log(this.checkupdate)
                      
            

                      //   window.location.reload()
                      //  console.log(this.AdminData)
                      
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
                  
}

//for check 
bindingGetData(){
  let CheckUpdate = JSON.parse(sessionStorage.getItem('check-update'));
    this.checkupdate = CheckUpdate.status;
  console.log('-----------------------'+this.checkupdate);
  
  if(this.checkupdate == true){
    alert('ข้อมูลได้รับการบันทึกเรียบร้อย');
    this.buttonDisabled = true;
    this.getAdminDetail();
  }else{
    alert('ข้อมูลบันทึกไม่สำเร็จ โปรดลองใหม่อีกครั้ง');
  }

}
// ------------------------------------------------------------------------------------------------------------------------------

  // button open Edit
  buttonEdit(){
    if(this.buttonDisabled = true){
      document.getElementById("buttonEdit").innerHTML="Save";
      this.buttonDisabled = false;
    }else { }
  }

  //button save 
  buttonSave(){
    this.UpdateAdminProfile();
    this.getAdminDetail();
  }

  // button cancel edit
  buttonCancel(){
      this.getAdminDetail();
      this.buttonDisabled = true;
  }

  // Check Number Only
  CheckNum($event: KeyboardEvent) {
    console.log($event)
    let value = (<HTMLInputElement>event.target).value;
      (<HTMLInputElement>event.target).value = value.replace(/\D/g, '');
  }

  ngOnInit() {
    this.activeMenu();
    this.getAdminDetail();
  }

}
