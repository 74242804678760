import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';

@Component({
  selector: 'app-job-order',
  templateUrl: './job-order.component.html',
  styleUrls: ['./job-order.component.scss']
})
export class JobOrderComponent implements OnInit {

  public typejob = <any>'';

  constructor() { }

  public getTypejoborder(){
      this.typejob = sessionStorage.getItem('typeJobOrder');
      console.log(this.typejob);
  }

  ngOnInit() {
    this.getTypejoborder();
  }

}
