import { Component, OnInit } from '@angular/core';
import { FormsModule, FormControl, Validators } from '@angular/forms';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2'
import { Observable, of } from 'rxjs/';
// Services
import { BookedRsvnService } from './booked-rsvn.service';
// Interfaces
import { BookedRsvnInterface } from './booked-rsvn-interface';
import { Session } from 'protractor';

@Component({
  selector: 'app-booked-rsvn',
  templateUrl: './booked-rsvn.component.html',
  styleUrls: ['./booked-rsvn.component.scss'],
  providers: [BookedRsvnService]
})
export class BookedRsvnComponent implements OnInit {

  userId = '1084873764';
  public activeSideNav = 'bookedstatistics';

  // interface
  _getBookingStatistics: BookedRsvnInterface;

  public highlightId :number;
  public res_cancel = <any>'';
  public res_recover = <any>'';
  public cancelbooking_ : boolean = false;


  // page
  // public routeLink = "['/user/'+this.userId+'/reservations/booked']";

  public iPage: number[] = [];
  public iPageStart: number = 1;
  public prevPage: number;
  public nextPage: number;
  public activePage: number;
  public totalItem: number;
  public perPage: number = 25;
  public totalPage: number;
  public maxShowPage: number;
  public useShowPage: number = 5;
  public pointStart: number = 0;
  public pointEnd: number;

  constructor(
    private BookedRsvnService: BookedRsvnService,
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  // 1. print
  public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(1));
    sessionStorage.setItem('sub-menu',JSON.stringify(101));
  }

  //------------------ Start Page ------------------------
  PagePagination(){
    // reset
    this.iPage = [];
    
    this.activePage = 1;
    this.nextPage = 2;
    this.pointEnd = this.perPage*this.activePage;

    this.totalPage = Math.ceil(this.totalItem/this.perPage);
    if(this.totalPage>this.useShowPage){
      this.useShowPage = 5;
    }else{
      this.useShowPage = this.totalPage;
    }

    for(let i=this.iPageStart; i<=this.useShowPage; i++){
      this.iPage.push(i);
    }

    this.route
        .queryParams
        .subscribe((data: {page: any}) => {
          if(data!=null && data.page!=null){
            this.activePage = +data.page;
            this.prevPage = this.activePage-1;
            this.nextPage = this.activePage+1;
            this.pointStart = (this.activePage-1)*this.perPage;
            this.pointEnd = this.perPage*this.activePage;
            this.pagination();
          }
        });

    let params = this.route.snapshot.paramMap;
    if(params.has('transactionId')){
      this.highlightId = +params.get('transactionId');
    }
  }

  changePage(page:number){
    this.activePage = page;
    let link = '/user/reservations/booked';
    this.router.navigate([link], {queryParams:{page:page}});
    // this.router.navigate([link], {queryParams:{page:page}});
  }

  pagination(){
    if(this.activePage > this.useShowPage){
      if(this.activePage+2 <= this.totalPage){
        this.iPageStart = this.activePage-2;
        this.maxShowPage = this.activePage+2;
      }else{
        if(this.activePage <= this.totalPage){
          this.iPageStart = (this.totalPage+1)-this.useShowPage;
          this.maxShowPage = (this.iPageStart-1)+this.useShowPage;
        }
      }
      this.iPage = [];
      for(let i=this.iPageStart; i<=this.maxShowPage; i++){
        this.iPage.push(i);
      }
    }else{
      this.iPageStart = 1;
      this.iPage = [];
      for(let i=this.iPageStart; i<=this.useShowPage; i++){
        this.iPage.push(i);
      }
    }
  }

  //------------------ End Page ------------------------

  // JSON booked stat from API
  getInvoiceFromData(): void{
    this.BookedRsvnService.getBookedData()
      .subscribe(
        resultArray => [
          this._getBookingStatistics = resultArray,
          console.log(this._getBookingStatistics),
          this.lengthDataFromGet(),
          this.PagePagination()
        ],
        error => console.log("Error :: " + error)
      )
  }

  // Length data
  lengthDataFromGet(){
    let count = 0;
    for(var tour in this._getBookingStatistics){
      count++;
    }
    this.totalItem = count;
  }

  // cancel Booking
  DelectBooking(booking){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
          this.DelectBookingtoAPI(booking);
        setTimeout( () => {
          if(this.res_cancel == true){
            Swal.fire(
            'Deleted!',
            'Your Booking has been deleted.',
            'success'
          )
              this.cancelbooking_ = true;
        }else{
            Swal.fire(
              'Not Deleted!',
              'ERROR! Plese,contact IT team.',
              'error'
            )
          } }, 3000 );
      }
    })
  }

  //  Recovery Booking
  RecoverBooking(booking){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, recovery it!'
    }).then((result) => {
      if (result.value) {
          this.RecoverBookingtoAPI(booking);
        setTimeout( () => {
          if(this.res_recover== true){
            Swal.fire(
            'Recovery!',
            'Your Booking has been recovery.',
            'success'
          )
              this.cancelbooking_ = true;
        }else{
            Swal.fire(
              'Not Recovery!',
              'ERROR! Plese,contact IT team.',
              'error'
            )
          } }, 3000 );
        }
      })
    }

  // cancel Booking to API ---------------------------------------------------------------
  public DelectBookingtoAPI(booking){

    let username = JSON.parse(localStorage.getItem("users"));

    let options = new RequestOptions();
    let setData = {
      username : username.data.name,
      transaction_id : booking     
    }
    console.log(setData);
    // let url = 'http://localhost:9000/api/Reservations/CancelBooking';
    let url = 'http://api.tourinchiangmai.com/api/Reservations/CancelBooking';
    
     return this.http.post(url, setData, options)
                    .map(res => res.json())
                    .subscribe(
                      data => [

                        this.res_cancel = data,
                        console.log(this.res_cancel)

                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

  // Recover Booking to API ---------------------------------------------------------------
  public RecoverBookingtoAPI(booking){
    let username = JSON.parse(localStorage.getItem("users"));

    let options = new RequestOptions();
    let setData = {
      username : username.data.name,
      transaction_id : booking     
    }
    // let url = 'http://localhost:9000/api/Reservations/RecoverBooking';
    let url = 'http://api.tourinchiangmai.com/api/Reservations/RecoverBooking';
    // 
     return this.http.post(url, setData, options)
                    .map(res => res.json())
                    .subscribe(
                      data => [

                        this.res_recover = data

                      ],
                      err => [
                        console.log(err)
                      ]
                    );
  }

  ngOnInit() {
    this.activeMenu();
    this.getInvoiceFromData();
  }
}