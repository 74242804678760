import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute, ChildActivationEnd } from '@angular/router';

@Component({
  selector: 'app-table-booking-guide',
  templateUrl: './table-booking-guide.component.html',
  styleUrls: ['./table-booking-guide.component.scss']
})
export class TableBookingGuideComponent implements OnInit {
  public Databooking = <any>'';
  public DataGuestName = <any>'';
  public getDetails = <any>'';
  public privacy = <any>'';
  public privacy_ : boolean = false;
  public detail_spc : boolean = false;
  public guide = <any>'';
  public guide_tel = <any>'';
  public driver = <any>'';
  public driver_tel = <any>'';
  public DataHotel = <any>'';
  public DataHotelphone = <any>'';
  public Billerstatusnamelst : Array<any> = [];
  public _newArray : Array<any> = [];
  public pax : Array<any> = [];
  public round_pax : Array<any> = [];
  public count = <any>'';
  public _pax0 = <any>'';
  public _pax1 = <any>'';
  public _pax2 = <any>'';
  public _pax3 = <any>'';
  public _pax4 = <any>'';
  public _pax5 = <any>'';
  public _pax6 = <any>'';
  public _pax7 = <any>'';
  public _pax8 = <any>'';
  public _pax9 = <any>'';
  public _pax10 = <any>'';
  public _pax11 = <any>'';
  public _pax12 = <any>'';
  public row : Array<any> = [];
  public _hotelRoom : Array<any> = [];
  public _bookBy : Array<any> = [];
  public _payment: Array<any> = [];
  public _otacode: Array<any> = [];
  public _perPrice: Array<any> = [];
  public _perPriceChild: Array<any> = [];
  public _perPriceSum: Array<any> = [];
  public user = <any>'';
  public username = <any>'';
  public date = new Date();
  public noted = <any>'';
  public noted_check : boolean = false;
  public unit = {
            pax : <any>'',
  };

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  // 1. print
  public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(4));
    sessionStorage.setItem('sub-menu',JSON.stringify(401));
  }

  // 3. get data Program Tour
public getDetailTours(){
  // post to API
  let url = "./../../../../assets/json/joborder/details_of_tours.json";
  let options = new RequestOptions();
  return this.http.get(url,options)
                  .map(res => res.json())
                  .subscribe(
                    data => [

                      this.getDetails= data,
                      
                    ],
                    err => [
                      console.log(err)
                    ]
                  );
}

  //4. get select book to create job order
  public getBookingSelect(){
    this.Databooking = sessionStorage.getItem('invoice_select');
    this.Databooking = JSON.parse(this.Databooking);

    let _guestName : Array<any> = [];
    // let _newArray : Array<any> = [];
    // push guest name in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
        for(let j=0;j<=20;j++){
          if(this.Databooking[i].guestName[j]){
             _guestName.push(this.Databooking[i].guestName[j]);
          }
        }
      }

      // push hotel room in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
            this._hotelRoom.push(this.Databooking[i].hotelRoom);
      }

      // push bookby in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
             this._bookBy.push(this.Databooking[i].bookBy);
      }

      // push payment in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
        if(this.Databooking[i].payment == 'E-Voucher'){
              this._payment.push(this.Databooking[i].payment);
            }else if(this.Databooking[i].payment == 'Voucher'){
                this._payment.push(this.Databooking[i].payment);
                  }else if(this.Databooking[i].payment == 'No Voucher'){
                      this._payment.push(this.Databooking[i].payment);
                        }else{
                          this._payment.push('Collect'+' '+'@'+' '+this.Databooking[i].payment);
                          }
         }

      // push perPrice in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
            if(this.Databooking[i].childpax == 1 && this.Databooking[i].adultpax == 1 ){
                if(this.Databooking[i].payment == 'E-Voucher' || this.Databooking[i].payment == 'Voucher' || this.Databooking[i].payment == 'No Voucher' ){
                  this._perPrice.push('undefined');
                    }else{
                      this._perPrice.push(this.Databooking[i].adultprice +' + '+ this.Databooking[i].childprice +' = '+ this.Databooking[i].price);
                  }
            }else{
              if(this.Databooking[i].payment == 'E-Voucher' || this.Databooking[i].payment == 'Voucher' || this.Databooking[i].payment == 'No Voucher' ){
                  this._perPrice.push('undefined');
                  }else{
                         this._perPrice.push(this.Databooking[i].adultprice +' x '+ this.Databooking[i] .adultpax+ 'P = ' + this.Databooking[i].totaladultprice);
                  }
              }
      }

      // push perPrice - child in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
            if(this.Databooking[i].childpax == 1 && this.Databooking[i].adultpax == 1 ){
              this._perPriceChild.push('undefined');
            }else if(this.Databooking[i].childpax >= 2){
                  if(this.Databooking[i].payment == 'E-Voucher' || this.Databooking[i].payment == 'Voucher' || this.Databooking[i].payment == 'No Voucher' ){
                      this._perPriceChild.push('undefined');
                  }else{
                      this._perPriceChild.push(this.Databooking[i].childprice+ ' x ' +this.Databooking[i].childpax+ 'P = ' +this.Databooking[i].totalchildprice);
                  }
            }else if(this.Databooking[i].adultpax >=2 && this.Databooking[i].childpax == 1 ){
                this._perPriceChild.push('+ '+this.Databooking[i].childprice+ ' = ' +this.Databooking[i].price);
            }
      }

      // push perPrice - child > 1 in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
            if(this.Databooking[i].childpax >= 2){
              if(this.Databooking[i].payment == 'E-Voucher' || this.Databooking[i].payment == 'Voucher' || this.Databooking[i].payment == 'No Voucher' ){
                this._perPriceSum.push('undefined');
            }else{
                this._perPriceSum.push(' = ' +this.Databooking[i].price);
              }
            }else{
              this._perPriceSum.push('undefined');
            }
      }

      // push ota code in new array
      for(let i=0;i<=this.Databooking.length-1;i++){
            if(this.Databooking[i].payment !== 'E-Voucher'){
                    this._otacode.push('undefined');
            }else{
                    this._otacode.push(this.Databooking[i].otacode);
                  }
      }

        this.DataGuestName = _guestName;
        this.getDatanewArray_Hotel();
        this.setDatashowTable();
  }

// set Data to show on Job Order Tables
  setDatashowTable(){

  //  set loop for Round insert Hotel
    for(let i=0;i<=this.Databooking.length-1;i++){
            this.pax.push(this.Databooking[i].adultpax + this.Databooking[i].childpax + this.Databooking[i].infantpax);
      }
        let pax_ = this.pax;
        let sum_round = 0;
        for(let l=0;l<=this.pax.length-1;l++){             
            sum_round = sum_round + this.pax[l];
            this.round_pax.push(sum_round);
        }
        this.pax = this.round_pax;
        this.pax.pop();

        this._pax1 = this.round_pax[0];
        this._pax2 = this.round_pax[1];
        this._pax3 = this.round_pax[2];
        this._pax4 = this.round_pax[3];
        this._pax5 = this.round_pax[4];
        this._pax6 = this.round_pax[5];
        this._pax7 = this.round_pax[6];
        this._pax8 = this.round_pax[7];
        this._pax9 = this.round_pax[8];
        this._pax10 = this.round_pax[9];
        this._pax11 = this.round_pax[10];
        this._pax12 = this.round_pax[11];

    //set rowspan Hotel Data
        let q=0;
        for(let i=0;i<=this.DataGuestName.length-1;i++){
            this.round_pax.splice(0,0,0);
                  if(i == this.round_pax[q]){
                      this.row.push(pax_[q]);
                      q=q+1;
                  }else{
                      this.row.push(0);
                  }
                  this.round_pax.splice(0,1);
        }
        
    //insert Guest Hotel Room Agent Payment to Array
      for(let i=0;i<=this.DataGuestName.length-1;i++){
            if(i == 0){
              this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[i],room : this._hotelRoom[i],bookby : this._bookBy[i],hotelphone : this.DataHotelphone[i],payment : this._payment[i],otacode : this._otacode[i],perprice : this._perPrice[i],perpricechild : this._perPriceChild[i],perpricesum : this._perPriceSum[i]});
            }else{
              for(let j=1;j<=this.DataHotel.length;j++){
                for(let k=0;k<=this.pax.length;k++){
                    if(i == this.pax[k]){
                        this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j],room : this._hotelRoom[j],bookby : this._bookBy[j],hotelphone : this.DataHotelphone[j],payment : this._payment[j],otacode : this._otacode[j],perprice : this._perPrice[j],perpricechild : this._perPriceChild[j],perpricesum : this._perPriceSum[j]});
                    }else if(i == this.pax[k+1]){
                          this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+1],room : this._hotelRoom[j+1],bookby : this._bookBy[j+1],hotelphone : this.DataHotelphone[j+1],payment : this._payment[j+1],otacode : this._otacode[j+1],perprice : this._perPrice[j+1],perpricechild : this._perPriceChild[j+1],perpricesum : this._perPriceSum[j+1]});
                    }else if(i == this.pax[k+2]){
                          this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+2],room : this._hotelRoom[j+2],bookby : this._bookBy[j+2],hotelphone : this.DataHotelphone[j+2],payment : this._payment[j+2],otacode : this._otacode[j+2],perprice : this._perPrice[j+2],perpricechild : this._perPriceChild[j+2],perpricesum : this._perPriceSum[j+2]});
                    }else if(i == this.pax[k+3]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+3],room : this._hotelRoom[j+3],bookby : this._bookBy[j+3],hotelphone : this.DataHotelphone[j+3],payment : this._payment[j+3],otacode : this._otacode[j+3],perprice : this._perPrice[j+3],perpricechild : this._perPriceChild[j+3],perpricesum : this._perPriceSum[j+3]});
                    }else if(i == this.pax[k+4]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+4],room : this._hotelRoom[j+4],bookby : this._bookBy[j+4],hotelphone : this.DataHotelphone[j+4],payment : this._payment[j+4],otacode : this._otacode[j+4],perprice : this._perPrice[j+4],perpricechild : this._perPriceChild[j+4],perpricesum : this._perPriceSum[j+4]});
                    }else if(i == this.pax[k+5]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+5],room : this._hotelRoom[j+5],bookby : this._bookBy[j+5],hotelphone : this.DataHotelphone[j+5],payment : this._payment[j+5],otacode : this._otacode[j+5],perprice : this._perPrice[j+5],perpricechild : this._perPriceChild[j+5],perpricesum : this._perPriceSum[j+5]});
                    }else if(i == this.pax[k+6]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+6],room : this._hotelRoom[j+6],bookby : this._bookBy[j+6],hotelphone : this.DataHotelphone[j+6],payment : this._payment[j+6],otacode : this._otacode[j+6],perprice : this._perPrice[j+6],perpricechild : this._perPriceChild[j+6],perpricesum : this._perPriceSum[j+6]});
                    }else if(i == this.pax[k+7]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+7],room : this._hotelRoom[j+7],bookby : this._bookBy[j+7],hotelphone : this.DataHotelphone[j+7],payment : this._payment[j+7],otacode : this._otacode[j+7],perprice : this._perPrice[j+7],perpricechild : this._perPriceChild[j+7],perpricesum : this._perPriceSum[j+7]});
                    }else if(i == this.pax[k+8]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+8],room : this._hotelRoom[j+8],bookby : this._bookBy[j+8],hotelphone : this.DataHotelphone[j+8],payment : this._payment[j+8],otacode : this._otacode[j+8],perprice : this._perPrice[j+8],perpricechild : this._perPriceChild[j+8],perpricesum : this._perPriceSum[j+8]});
                    }else if(i == this.pax[k+9]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+9],room : this._hotelRoom[j+9],bookby : this._bookBy[j+9],hotelphone : this.DataHotelphone[j+9],payment : this._payment[j+9],otacode : this._otacode[j+9],perprice : this._perPrice[j+9],perpricechild : this._perPriceChild[j+9],perpricesum : this._perPriceSum[j+9]});
                    }else if(i == this.pax[k+10]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+10],room : this._hotelRoom[j+10],bookby : this._bookBy[j+10],hotelphone : this.DataHotelphone[j+10],payment : this._payment[j+10],otacode : this._otacode[j+10],perprice : this._perPrice[j+10],perpricechild : this._perPriceChild[j+10],perpricesum : this._perPriceSum[j+10]});
                    }else if(i == this.pax[k+11]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+11],room : this._hotelRoom[j+11],bookby : this._bookBy[j+11],hotelphone : this.DataHotelphone[j+11],payment : this._payment[j+11],otacode : this._otacode[j+11],perprice : this._perPrice[j+11],perpricechild : this._perPriceChild[j+11],perpricesum : this._perPriceSum[j+11]});
                    }else if(i == this.pax[k+12]){
                      this._newArray.push({nameguest : this.DataGuestName[i],namehotel : this.DataHotel[j+12],room : this._hotelRoom[j+12],bookby : this._bookBy[j+12],hotelphone : this.DataHotelphone[j+12],payment : this._payment[j+12],otacode : this._otacode[j+12],perprice : this._perPrice[j+12],perpricechild : this._perPriceChild[j+12],perpricesum : this._perPriceSum[j+12]});
                    }else{
                      this._newArray.push({nameguest : this.DataGuestName[i]});
                    }break;
                }break;
              } 
            } 
        }
  }

  getDatanewArray_Hotel(){
    let _hotel : Array<any> = [];
    //push hotel in new array
    for(let i=0;i<=this.Databooking.length-1;i++){
           _hotel.push(this.Databooking[i].hotel);
    }
      this.DataHotel = _hotel;
  

      let _hotelphone: Array<any> = [];
      //push hotel phone in new array
        for(let i=0;i<=this.Databooking.length-1;i++){
            _hotelphone.push(this.Databooking[i].hotelphone);
        }
          this.DataHotelphone = _hotelphone;

    //check privacy
        if(this.Databooking[0].tourPrivacy == "Join"){
          this.privacy_ = false;
      }else{
          this.privacy_ = true;
      }
  }


    // ----------------------------------------------------------------------------|  Detail Join Process  |------------------------------------------------------------------------------------- 


  //5.set unit at details & process Amount
  public getunitBooking(){

    //get adult_pax
    let adult_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        adult_pax = adult_pax + this.Databooking[i].adultpax;
      }
    //get chlid_pax
    let child_pax = 0;
      for(let i=0;i<=this.Databooking.length-1;i++){
        child_pax = child_pax + this.Databooking[i].childpax;
      }
      //get infant_pax
    let infant_pax = 0;
    for(let i=0;i<=this.Databooking.length-1;i++){
      infant_pax = infant_pax + this.Databooking[i].infantpax;
    }
    // all pax
    this.unit.pax = adult_pax + child_pax;
    this.count = this.unit.pax + infant_pax ;   // *** count of guest
  
  }

  // get user data
    public getUserData(){
      this.user = localStorage.getItem('users');
      this.user = JSON.parse(this.user);
      this.username = this.user.data.name;
  }

  //get DATA Guide & Driver
    public getDataGuidesDirver(){
      let DataGuidesDirver;
      DataGuidesDirver = sessionStorage.getItem('guide_driver');
      DataGuidesDirver = JSON.parse(DataGuidesDirver);
      this.guide = DataGuidesDirver.data.Guide;
      this.driver = DataGuidesDirver.data.Driver;
      this.guide_tel = DataGuidesDirver.data.Phone_Guide;
      this.driver_tel = DataGuidesDirver.data.Phone_Driver;
    }
    
  ngOnInit() {
      this.getDetailTours();
      this.getBookingSelect();
      this.getunitBooking();
      this.getUserData();
      this.getDataGuidesDirver();
  }

}
