import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-job-order-rsvn',
  templateUrl: './job-order-rsvn.component.html',
  styleUrls: ['./job-order-rsvn.component.scss']
})
export class JobOrderRsvnComponent implements OnInit {

  constructor() { }

   // 1. print
   public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(3));
    sessionStorage.setItem('sub-menu',JSON.stringify(301));
  }

  // 3. add job order
  addJobOrder(){
      
  } 
  
  ngOnInit() {
  }

}
