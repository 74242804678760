import { Component, OnInit } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dialog-content-example-dialog',
  templateUrl: './dialog-content-example-dialog.component.html',
  styleUrls: ['./dialog-content-example-dialog.component.scss']
})
export class DialogContentExampleDialogComponent implements OnInit {

  public highlightId :number;
  public getTraveling = <any>[];
  public travelingData = <any>"";
  public getTraveling_ = '';

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  // JSON booked stat from API
  getUserData(): void{
    // let url = "http://localhost:9000/api/Reservations/GetUpdateTraveledlastWeek";
    let url = "http://api.tourinchiangmai.com/api/Reservations/GetUpdateTraveledlastWeek";

    // set data to save
    // let _getUserData = JSON.parse(sessionStorage.getItem('users'));
    let _getUserData = JSON.parse(localStorage.getItem('users'));
    let dataSave = {
      token : _getUserData.data.token
    };

    let options = new RequestOptions();
    /*==================  Success  ===================*/
    this.http.post(url, dataSave, options)
                    .map(res => res.json())
                    .subscribe(
                      data => [
                        this.lengthDataFromGet(data.data),
                      ],
                      err => {console.log(err)}
                    );
    /*==================  Success  ===================*/
    setTimeout(()=>{
      // this.lengthDataFromGet(this.getUser);
      // this.PagePagination();
    }, 500);
  }

  // Length data
  lengthDataFromGet(getUser){
    this.getTraveling = getUser;

    let count = 0;
    for(var tour in getUser){
      count++;
    }
  }

  ngOnInit() {
    this.getUserData();
  }

}
