import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invoice-important-note-rsvn',
  templateUrl: './invoice-important-note-rsvn.component.html',
  styleUrls: ['./invoice-important-note-rsvn.component.scss']
})
export class InvoiceImportantNoteRsvnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
