import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

@Component({
  selector: 'app-home-user',
  templateUrl: './home-user.component.html',
  styleUrls: ['./home-user.component.scss']
})
export class HomeUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    AOS.init({
      easing: 'ease',
      once: true
    });
  }

}
