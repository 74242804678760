import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-sidebar-rsvn',
  templateUrl: './menu-sidebar-rsvn.component.html',
  styleUrls: ['./menu-sidebar-rsvn.component.scss']
})
export class MenuSidebarRsvnComponent implements OnInit {

  // userId = '1084873764';

  constructor() { }

  ngOnInit() {
  }

}
