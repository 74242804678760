import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password-user',
  templateUrl: './change-password-user.component.html',
  styleUrls: ['./change-password-user.component.scss']
})
export class ChangePasswordUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
