import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reservation-changepassword',
  templateUrl: './reservation-changepassword.component.html',
  styleUrls: ['./reservation-changepassword.component.scss']
})
export class ReservationChangepasswordComponent implements OnInit {

  constructor() { }

    // 1. print
    public print():void {
      window.print();
    }
  
    // 2. active menu
    public activeMenu(){
      // set storage
      sessionStorage.setItem('menu',JSON.stringify(7));
      sessionStorage.setItem('sub-menu',JSON.stringify(701));
    }

  ngOnInit() {
  }

}
