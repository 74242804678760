import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Http, RequestOptions } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manager-changepassword',
  templateUrl: './manager-changepassword.component.html',
  styleUrls: ['./manager-changepassword.component.scss']
})
export class ManagerChangepasswordComponent implements OnInit {

  constructor(
    private http: Http,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  public Disabled : boolean =true;
  public oldpassword = <any>'';
  public CheckPassword  = <any>'';
  public UpdatePassword  = <any>'';
  public newpassword = <any>'';
  public newpasswordcomfirm = <any>'';

  // 1. print
  public print():void {
    window.print();
  }

  // 2. active menu
  public activeMenu(){
    // set storage
    sessionStorage.setItem('menu',JSON.stringify(6));
    sessionStorage.setItem('sub-menu',JSON.stringify(601));
  }


 //3. get Token and old password
 public getUserToken(){
  // get token
   let getUser = JSON.parse(localStorage.getItem('users'));
   let userToken = getUser.data.token;

  //get old-password
    let getPassword = JSON.parse(sessionStorage.getItem('old-password'));
    // console.log(getPassword);

   // set data
  let setData = {
    Token: userToken,
    Password : getPassword
   };
  //  console.log(setData);

   // post to API
   //let url = 'http://localhost:9000/api/Dashboard/Member/Profile';
    let url = 'http://api.tourinchiangmai.com/api/Dashboard/ChangePassword';

   let options = new RequestOptions();

    this.http.post(url, setData, options)
                   .map(res => res.json())
                   .subscribe(
                     data => [       

                       sessionStorage.setItem('check-password',JSON.stringify(data)),
                        this.bindingGetData(),
                     ],
                     err => [
                       console.log(err)
                     ]
                   );     
 }

//for old password
bindingGetData(){
  let checkPassword = JSON.parse(sessionStorage.getItem('check-password'));
    this.CheckPassword = checkPassword.status;
  console.log('-----------------------'+this.CheckPassword);
  
  if(this.CheckPassword == true){
    this.Disabled = false;
 }else{
   alert('รหัสผ่านเดิมไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง\nPassword not Corret , Please! Try Again');
 }

}

// -----------------------------------------------------------------------------------------------------------------------------------------

//4. get Token and new password
public getUsernewToken(){
// get token
 let getUser = JSON.parse(localStorage.getItem('users'));
 let userToken = getUser.data.token;

//get new-password
  let getPassword = JSON.parse(sessionStorage.getItem('new-password'));
   console.log(getPassword);

 // set data
let setData = {
  Token: userToken,
  Password : getPassword
 };
//  console.log(setData);

 // post to API
 //let url = 'http://localhost:9000/api/Dashboard/Member/Profile';
  let url = 'http://api.tourinchiangmai.com/api/Dashboard/UpdatePassword';

 let options = new RequestOptions();

  this.http.post(url, setData, options)
                 .map(res => res.json())
                 .subscribe(
                   data => [       

                     sessionStorage.setItem('update-password',JSON.stringify(data)),
                      this.bindingGetnewData(),
                   ],
                   err => [
                     console.log(err)
                   ]
                 );     
}

//for new password
bindingGetnewData(){
let updatePassword = JSON.parse(sessionStorage.getItem('update-password'));
  this.UpdatePassword = updatePassword.status;
console.log('-----------------------'+this.UpdatePassword);

if(this.UpdatePassword == true){
  alert('รหัสผ่านใหม่ได้รับการเปลี่ยนแปลงเรียบร้อยแล้ว\nUpdate Password Complete')
  this.Disabled = true;
}else{
 alert('ไม่สามารถเปลี่ยนรหัสผ่านใหม่ได้ กรุณาติดต่อ:(แผนก IT)\nPassword not Update , please! Contact : IT SUPPORT');
}

}

// -----------------------------------------------------------------------------------------------------------------------------------------

//Button Check Passsword
buttonChange(){
    sessionStorage.setItem('old-password',JSON.stringify(this.oldpassword));
    this.getUserToken();
}



//Button Update Passsword
buttonSave(){
  if(this.newpassword == this.newpasswordcomfirm){
  //   setTimeout(function(){
  //     alert('กรุณารอสรักครู่ ระบบกำลังทำการอัพเดตข้อมูล ');
  // }, 3000);
  sessionStorage.setItem('new-password',JSON.stringify(this.newpassword));
  this.getUsernewToken();
  sessionStorage.removeItem('old-password');
  sessionStorage.removeItem('new-password');
  }else{
    alert('รหัสผ่านไม่ตรงกัน! กรุณาตรวจสอบรหัสผ่านของท่านใหม่อีกครั้ง')
  }
}

ngOnInit() {
  this.activeMenu();
}

}
