import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-aff-member',
  templateUrl: './request-aff-member.component.html',
  styleUrls: ['./request-aff-member.component.scss']
})
export class RequestAffMemberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
