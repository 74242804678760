import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emailthankyou',
  templateUrl: './emailthankyou.component.html',
  styleUrls: ['./emailthankyou.component.scss']
})
export class EmailthankyouComponent implements OnInit {

  public email : any[] = [{
    customer: ''
  }];
  public disable : boolean = true;

  constructor() { }

  showMore(){
    this.disable = false;
  }

  hiddenMore(){
    this.disable = true;
  }


  addAddress() {
    this.email.push({
      customer: ''
    });
  }

  removeAddress(i: number) {
    this.email.splice(i, 1);
  }

  logValue(index) {
    console.log(this.email);
    this.email.splice(index = 1);
  }

  ngOnInit() {
  }

}
